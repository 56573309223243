




























































































































import { Component, Vue } from "vue-property-decorator";
import * as Bridge from "../services/Bridge";
import * as DateService from '../services/Date';
import MapFilter from "../components/MapFilter.vue";
import FilterIcon from '../assets/icon_filter.svg';
import CalendarIcon from '../assets/calendar.svg';
import TrashIcon from '../assets/icon_trash.svg';

@Component({
    components: {
      MapFilter,
      FilterIcon,
      CalendarIcon,
      TrashIcon
    }
})

export default class Bookings extends Vue {
  private bookings: Array<Bridge.Booking> = [];
  private userLogin = Bridge.user.login;
  private isAdmin = Bridge.user.isAdmin;

  // filters
  private searchedUser = '';

  private filterFloor = false;
  private selectedFloor = 1;
  private floors = [
    { value: null, text: this.$t('Level'), disabled: true },
    { value: 1, text: this.$t('Level') + ' 1' },
    { value: 2, text: this.$t('Level') + ' 2' },
    { value: 3, text: this.$t('Level') + ' 3' },
    { value: 4, text: this.$t('Level') + ' 4' },
  ];

  private bookingDetails = [
    { key: 'floor', i18n: 'Level' },
    { key: 'room', i18n: 'Room' },
    { key: 'bench', i18n: 'Bench' },
    { key: 'desk', i18n: 'Desk' }
  ]

  private filterDateRange = false;
  private selectedDateRange = {
    start: DateService.formatToISO(Date.now()),
    end: DateService.formatToISO(Date.now())
  }

  private selectedBooking: Bridge.Booking | null = null;

  private fields: Array<string> = [
    'login',
    'floor',
    'room',
    'bench',
    'desk',
    'begin_date',
    'end_date',
    'delete'
  ];

  // TODO - optimize
  private getVisibleBookings() {
    if (!this.isAdmin || this.$root.$data.isAdminMode) {
      return this.bookings;
    }

    return this.bookings.filter((booking) => booking.login === this.userLogin);
  }

  get filteredBookings() {
    let bookings = this.getVisibleBookings();

    if (this.searchedUser) {
      bookings = bookings.filter((booking => booking.login.toLowerCase().includes(this.searchedUser.toLowerCase())));
    }

    if (this.filterFloor) {
      bookings = bookings.filter((booking => booking.floor == this.selectedFloor));
    }

    if (this.filterDateRange) {
      bookings = bookings.filter((booking) =>
        DateService.intersectsRange(booking.begin_date, booking.end_date, this.selectedDateRange.start, this.selectedDateRange.end));
    }

    return bookings;
  }

  get notFoundImage() {
    const filename = this.$data.isAdminMode ? 'not_found_dark' : 'not_found';

    return require(`@/assets/${filename}.png`);
  }

  public created() {
    Bridge.runTask('booking/get', {'all': this.isAdmin }).then(response => {
      this.bookings = response.data;
    })
  }

  public formatDateShort(date: Date | string) {
    return DateService.formatShort(date);
  }

  public confirmCancellation(booking: Bridge.Booking) {
    this.selectedBooking = booking;
    this.$bvModal.show('delete-reservation-modal');
  }

  // TODO - duplicate in FloorMap.Vue
  private showToast(title: string, message: string) {
    this.$bvToast.toast(message, {
        title,
        autoHideDelay: 5000
    });
  }

  public cancelBooking(booking: Bridge.Booking) {
    Bridge.runTask('desk/unbook', {
      'login': booking.login,
      'begin_date': booking.begin_date,
      'end_date': booking.end_date
    }).then(response => {
      if (response.data[0].days > 0) {
        this.bookings.splice(this.bookings.indexOf(booking), 1);
        this.showToast(this.$t('Confirmed') as string, this.$t('Booking_cancelled') as string);
      }
    })
  }
}
