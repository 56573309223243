














































































































































import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import * as Bridge from '../services/Bridge';
import * as DateService from '../services/Date';
import deskCoordinatesJSON from '../assets/desk_coordinates.json';

import ArrowIcon from '../assets/arrow.svg';

@Component({
  components: {
    ArrowIcon
  }
})

export default class FloorMap extends Vue {
  @Prop() public beginDate!: string;
  @Prop() public endDate!: string;
  @PropSync('isUnavailable') public _isUnavailable!: boolean;

  private deskSvg!: HTMLCollectionOf<SVGPathElement>;
  private svgFile = '';
  private selectedDesk: HTMLElement | null = null;
  private isMapLoading = true;
  private benchSpacing = 2;
  private currentUserBooking: Bridge.Booking | null = null;

  public bookingsByDesk = {};
  public floor = 1;

  deskCoordinates = deskCoordinatesJSON;

  public created() {
    this.showBookings();
  }

  public formatDateShort(date: Date | string) {
    return DateService.formatShort(date);
  }

  public formatDate(date: Date | string) {
    return DateService.format(date);
  }

  @Watch('floor')
  private onFloorChange(val: number) {
    this.floor = val;
    this.showBookings();
  }

  @Watch('beginDate')
  private onbeginDateChange(val: string) {
    this.beginDate = val;

    this.showBookings();
  }

  @Watch('endDate')
  private onendDateChange(val: string) {
    this.endDate = val;

    this.showBookings();
  }

  private showBookings() {
    this.currentUserBooking = null;

    Bridge.getBookings(this.beginDate, this.endDate).then((bookings) => {
      this.bookingsByDesk = {};
      this.markAllDesksAsFree();

      bookings.forEach((booking) => {
        const isByCurrentUser = (booking.login === (Bridge.user.login) as string);

        if (isByCurrentUser) {
          this.currentUserBooking = booking;
        }

        if (booking.floor === this.floor) {
          const deskElement = (document.getElementById(booking.id) as HTMLElement);
          this.markDeskAsBooked(deskElement, isByCurrentUser);
        }

        if (!this.bookingsByDesk[booking.id]) {
          this.bookingsByDesk[booking.id] = { bookings: [ booking ] };

          return;
        }

        // two bookings on the same desk can't have the same begin_date
        const findCurrentBooking = this.bookingsByDesk[booking.id].bookings.find((listedBooking: Bridge.Booking) => listedBooking.begin_date === booking.begin_date);

        if (!findCurrentBooking) {
          this.bookingsByDesk[booking.id].bookings.push(booking);
        }
      });

      this.$forceUpdate();
    });
  }

  private click(event: Event) {
    const deskElement = ((event.srcElement as HTMLElement).parentElement as HTMLElement);

    if (deskElement.classList.contains('unvailable') || deskElement.classList.contains('booked') || deskElement.classList.contains('booked-by-user')) {
      return;
    }

    this.selectedDesk = deskElement;

    if (this.currentUserBooking) {
      this.$bvModal.show('replace-reservation-modal');
    } else {
      this.$bvModal.show('new-reservation-modal');
    }
  }

  private showToast(title: string, message: string) {
    this.$bvToast.toast(message, {
        title,
        autoHideDelay: 5000
    });
  }

  private markDeskAsFree(desk: HTMLElement) {
    desk.classList.remove('unavailable');
    desk.classList.remove('booked');
    desk.classList.remove('booked-by-user');
  }

  private markDeskAsBooked(desk: HTMLElement, byCurrentUser = false) {
    if (byCurrentUser) {
      desk.classList.add('booked-by-user');
    } else {
      desk.classList.add('booked');
    }
  }

  // A desk may be unavailable for various reasons - does not mean it is used.
  private markDeskAsUnavailable(desk: HTMLElement) {
    desk.classList.add('unavailable');
  }

  private markAllDesksAsFree() {
    (document.querySelectorAll('.desk') as NodeListOf<HTMLElement>).forEach(this.markDeskAsFree);
  }

  private markAllDesksAsUnavailable() {
    (document.querySelectorAll('.desk') as NodeListOf<HTMLElement>).forEach(this.markDeskAsUnavailable);
  }

  private markDeskAsBookedByUser(desk: HTMLElement) {
    this.markDeskAsBooked(desk);
    desk.classList.add('booked-by-user');
  }

  private replaceReservation() {
    if (!this.currentUserBooking) {
      return;
    }

    Bridge.cancelBooking(this.currentUserBooking.login,
      this.currentUserBooking.begin_date,
      this.currentUserBooking.end_date).then((unbookingSuccessful) => {
        if (!unbookingSuccessful) {
          this.showToast(this.$t('Failure') as string, this.$t('Unbooking_failure') as string);

          return;
        }

        this.makeReservation();
    });
  }

  private makeReservation() {
    Bridge.runTask('desk/book', {
      'desk'      : (this.selectedDesk as HTMLElement).id,
      'login'     : Bridge.user.login,
      'begin_date': this.beginDate,
      'end_date'  : this.endDate
    }).then(
      () => {
        this.markDeskAsBookedByUser(this.selectedDesk as HTMLElement);
        this.showBookings();

        this.showToast(this.$t('Confirmed') as string, this.$t('Booking_success') as string);
      },
      () => this.showToast(this.$t('Failure') as string, this.$t('Booking_failure') as string)
    );
  }
}
