














import { Component, Vue } from "vue-property-decorator";
import MapFilter from "../components/MapFilter.vue";
import FloorMap from "../components/FloorMap.vue";
import * as DateService from '../services/Date';

const DEFAULT_FLOOR = 1;

@Component({
    components: {
      MapFilter,
      FloorMap
    }
})

export default class Book extends Vue {
  private floor = DEFAULT_FLOOR;
  private dateRange = {
    start: DateService.formatToISO(Date.now()),
    end: DateService.formatToISO(Date.now())
  }

  private isUnavailable = false;
}
