













import { Component, Vue } from "vue-property-decorator";
import router from "../router";
import * as Bridge from "../services/Bridge";

@Component({})

export default class Login extends Vue {
  public created() {
    Bridge.isAuth().then(response => {
      if (response.status == 200)
        this.loadUserDetails();
    })
  }

  private loadUserDetails() {
    Bridge.loadUserDetails().then(response => {
      if (response.status == 200)
        router.push('/book')
    })
  }

  private signin() {
    Bridge.signIn();
  }
}
