import Vue from "vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/styles/main.scss';
import App from "./App.vue";
import router from './router'
import VCalendar from 'v-calendar'

import i18n from './i18n'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VCalendar);
Vue.config.productionTip = false;

const store = {
    state: {
        isAdminMode: false
    }
}

new Vue({
    render: h => h(App),
    router,
    i18n,
    data: store.state
})
.$mount("#app")
