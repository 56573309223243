










import { Component, Vue } from "vue-property-decorator";
import Navbar from "./components/Navbar.vue";

import * as Bridge from "./services/Bridge";

@Component({
  components: {
    Navbar
  }
})
export default class App extends Vue {
  appVersion = 'v0.1.5';

  private isAuth() {
    return Bridge.user.login != undefined;
  }
}
