import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'

import Bookings from '../views/Bookings.vue'
import Book from '../views/Book.vue'
import Login from '../views/Login.vue'
import * as Bridge from '../services/Bridge'

Vue.use(VueRouter)

function guard(to: Route, from: Route, next: Function) {
    if (to.path == '/login' || Bridge.user.login) {
        next();
    } else {
        next('/login');
    }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/book'
  },
  {
    path: '/book',
    name: 'Book',
    beforeEnter: guard,
    component: Book
  },
  {
    path: '/bookings',
    name: 'bookings',
    beforeEnter: guard,
    component: Bookings
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router
