







































import { Component, Vue } from "vue-property-decorator";
import * as Bridge from "../services/Bridge";

import ListIcon from '../assets/icon_list.svg';
import MapIcon from '../assets/icon_plan.svg';
import ProfileIcon from '../assets/icon_profile.svg';

@Component({
    components: {
      ListIcon,
      MapIcon,
      ProfileIcon
    }
})

export default class Login extends Vue {
  private login = Bridge.user.login;
  private isAdmin =  Bridge.user.isAdmin;

  get isAdminMode() {
    return this.$root.$data.isAdminMode
  }

  set isAdminMode(value) {
    this.$root.$data.isAdminMode = !this.$root.$data.isAdminMode;
  }

  signOut() {
    Bridge.signOut();
  }
}
