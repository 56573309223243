


































































import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
import * as Bridge from "../services/Bridge";

import CalendarIcon from '../assets/calendar.svg';

@Component({
  components: {
    CalendarIcon
  }
})

export default class MapFilter extends Vue {
  @PropSync("dateRange") public _dateRange!: {};
  @Prop() public isUnavailable!: boolean;

  private vm = this;
  private login = Bridge.user.login;
  private isAdmin =  Bridge.user.isAdmin;
}
